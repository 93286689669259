
.main-table {
    font-family: Arial, Helvetica, sans-serif;
    border-collapse: collapse;
    width: 100%;
}

.main-table td,
.main-table th {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: center;
}

.main-table tr:nth-child(even) {
    background-color: #f2f2f2;
}

.main-table tr:hover {
    background-color: #ddd;
}

.main-table th {
    font-size: smaller;
    padding-top: 12px;
    padding-bottom: 12px;
    text-align: center;
    background-color: #198754;
    color: black;
}

@media all and (max-width: 500px) {
    
}